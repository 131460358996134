module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-typescript","options":{"wrapperComponent":"MultiCodeBlock"}},"gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},"gatsby-remark-prismjs-title",{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}},"gatsby-remark-rewrite-relative-links",{"resolve":"gatsby-remark-check-links","options":{}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-theme-apollo-docs/gatsby-browser.js'),
      options: {"plugins":[],"siteName":"ENGAGEMENT","menuTitle":"ENGAGEMENT","trackingId":"","algoliaApiKey":"768e823959d35bbd51e4b2439be13fb7","algoliaIndexName":"apollodata","baseUrl":"https://www.bltgv.com/","twitterHandle":"BLTGV","spectrumHandle":"#","youtubeUrl":"#","logoLink":"https://www.bltgv.com/","navConfig":{"Business Objectives":{"url":"/process/understanding-tasks/#business-objectives","description":"What is it that you want us to complete? "},"Agreement":{"url":"/process/understanding-tasks/#agreement","description":"So, we have reached a solid mutual understanding of our objectives, constraints, and approach."},"Budget Schedules":{"url":"/process/setting-up/#budget-schedules","description":"Once in a while, we land a project where all costs are fixed and paid upfront, and we get to spread out the budget as we see fit."},"Continuous Optimizations":{"url":"/process/executing-the-plans/#continuous-optimizations","description":"There are things beyond anyone’s control; but for those within our control, we will work hard to keep your project team happy."},"Deployments":{"url":"/process/executing-the-plans/#deployments","description":"One of the most critical milestones of a project is the deployment, and nearly all projects have multiple deployments or even more."},"Knowledge Transfer":{"url":"/process/wrapping-up/#knowledge-transfer","description":"Not everything we have done fits neatly into a documentation form.."}},"footerNavConfig":{"Homepage":{"href":"https://www.bltgv.com/","target":"_blank","rel":"noopener noreferrer"}},"root":"/opt/build/repo","subtitle":"BLT Process","description":"BLT our process","githubRepo":"BLTGV/blt-process-website-gatsby","spectrumPath":"/","sidebarCategories":{"null":["index"],"Process":["process/understanding-tasks","process/setting-up","process/executing-the-plans","process/wrapping-up"]}},
    }]
