// const {colors} = require('@apollo/space-kit/colors');

// exports.colors = {
//   primary: colors.indigo.dark,
//   primaryLight: colors.indigo.lighter,
//   secondary: colors.pink.base,
//   tertiary: colors.teal.dark,
//   divider: colors.silver.dark,
//   background: colors.silver.light,
//   background2: colors.silver.base,
//   text1: colors.black.lighter,
//   text2: colors.grey.dark,
//   text3: colors.grey.light,
//   text4: colors.silver.darker,
//   warning: colors.yellow.base,
//   shadow: colors.black.darker,
//   highlight: colors.blue.base,
//   highlight2: colors.blue.lighter,
//   highlight3: colors.blue.lightest,
//   hoverOpacity: 0.8
// };
exports.colors = {
  primary: "#ffab01",
  primaryLight: "#ffc777",
  secondary: "#ffab01",
  tertiary: "#ffab01",
  divider: "#ffc777",
  background: "#ebebeb",
  background2: "#ebebeb",
  text1: "#474747",
  text2: "#707070",
  text3: "#d6d6d6",
  text4: "#d6d6d6",
  warning: "#ffab01",
  shadow: "#707070",
  highlight: "#ffab01",
  highlight2: "#ffc777",
  highlight3: "#ffc777",
  hoverOpacity: 0.8
};
